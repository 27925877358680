// @flow

import React from 'react'
import { Link } from 'gatsby'

import Image from 'src/common/components/Image'

import styles from './BlogCard.module.scss'

type Props = {
  image: string,
  title: string,
  body: string,
  link: string,
}

const BlogCard = ({ image, title, body, link }: Props) => {
  return (
    <div className={styles.container}>
      <Link className={styles.link} to={link}>
        <div className={styles.image}>
          <Image src={image} alt={title} widths={{ desktop: 560 }} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.body}>{body}</div>
        </div>
      </Link>
    </div>
  )
}

export default BlogCard

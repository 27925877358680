// @flow
import React from 'react'
import { useIntl } from 'react-intl'

import { linkToBlogPost } from 'src/links'
import BlogCard from 'src/components/BlogCard'

import styles from './BlogList.module.scss'

type Props = {|
  posts: Array<Object>,
|}

const BlogList = ({ posts }: Props) => {
  const { locale } = useIntl()

  return (
    <div className={styles.container}>
      {posts.map(post => (
        <BlogCard
          key={post.node.id}
          image={post.node.frontmatter.intro.image}
          title={post.node.frontmatter.intro.title}
          body={post.node.excerpt}
          link={linkToBlogPost(locale, post.node.fields.slug)}
        />
      ))}
    </div>
  )
}

export default BlogList
